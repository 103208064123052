import React, { useState } from 'react';
import '../Styles/DonationForm.css';

const SqFeetDonationForm = () => {
  const [sqFeetCount, setSqFeetCount] = useState(1);
  const [totalAmount, setTotalAmount] = useState(6000);
  const [donorInfo, setDonorInfo] = useState({
    firstName: '',
    lastName: '',
    country: '',
    address: '',
    city: '',
    state: '',
    pinCode: '',
    phone: '',
    email: '',
  });

  const handleSqFeetChange = (e) => {
    const value = Math.max(1, Math.min(20, parseInt(e.target.value)));
    setSqFeetCount(value);
    setTotalAmount(6000 * value);
  };

  const handleDonorInfoChange = (e) => {
    const { name, value } = e.target;
    setDonorInfo({
      ...donorInfo,
      [name]: value,
    });
  };

  return (
    <div className='df-bg'>

      <div className="donation-form-container">
        <h2>Sq. Feet Donation</h2>
        <div className="form-section">
          <h3>Donor Information</h3>
          <div className="input-group">
            <label>First Name:</label>
            <input type="text" name="firstName" value={donorInfo.firstName} onChange={handleDonorInfoChange} />
          </div>
          <div className="input-group">
            <label>Last Name:</label>
            <input type="text" name="lastName" value={donorInfo.lastName} onChange={handleDonorInfoChange} />
          </div>
          <div className="input-group">
            <label>Country:</label>
            <select name="country" value={donorInfo.country} onChange={handleDonorInfoChange}>
              <option value="">Select Country</option>
              <option value="India">India</option>
              <option value="USA">USA</option>
              <option value="Canada">Canada</option>
            </select>
          </div>
          <div className="input-group">
            <label>Address:</label>
            <input type="text" name="address" value={donorInfo.address} onChange={handleDonorInfoChange} />
          </div>
          <div className="input-group">
            <label>City:</label>
            <input type="text" name="city" value={donorInfo.city} onChange={handleDonorInfoChange} />
          </div>
          <div className="input-group">
            <label>State:</label>
            <input type="text" name="state" value={donorInfo.state} onChange={handleDonorInfoChange} />
          </div>
          <div className="input-group">
            <label>Pin Code:</label>
            <input type="text" name="pinCode" value={donorInfo.pinCode} onChange={handleDonorInfoChange} />
          </div>
          <div className="input-group">
            <label>Phone:</label>
            <input type="text" name="phone" value={donorInfo.phone} onChange={handleDonorInfoChange} />
          </div>
          <div className="input-group">
            <label>Email:</label>
            <input type="email" name="email" value={donorInfo.email} onChange={handleDonorInfoChange} />
          </div>
        </div>

        <div className="form-section donation-details">
          <h3>Donation Details</h3>
          <label>Square Feet Count (1-20):</label>
          <input type="number" value={sqFeetCount} onChange={handleSqFeetChange} min="1" max="20" />
          <p>Total Amount: ₹{totalAmount}</p>
          <button className="donatenow-button">Donate Now</button>
        </div>
      </div>
    </div>
  );
};

export default SqFeetDonationForm;
