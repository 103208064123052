import React from 'react'
import '../Styles/Donation.css'
import { Link } from 'react-router-dom';


const Donation = () => {
    return (
        <div>
            <div className="headbg">
                <div className="head1">BECOME A SUDAMA SEVAK</div>
                <div className="head2">Sudama Gave Poha to Krishna and Got many Things. Now Your Turn. By sponsoring the tile, your name will remain on temple wall forever. Lorem ipsum dolor sit amet consectetur adipisicing elit.</div>
            </div>

            <div className='wrap'></div>
            <h1 className='h1'>DONATE FOR TEMPLE CONSTRUCTION</h1>

            <div class="cards-container">
                <div class="card">
                    <div class="card-image">
                        <div class="overlay">
                            <h2 class="card-heading">Card Heading</h2>
                            <Link to="/donationform">
                                <button className="donate-button">Donate Now</button>
                            </Link>

                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-image">
                        <div class="overlay">
                            <h2 class="card-heading">Card Heading</h2>
                            <Link to="/donationform">
                                <button className="donate-button">Donate Now</button>
                            </Link>

                        </div>
                    </div>
                </div>
            </div>

            <div className='gh'> 80G AVAILABLE AS PER INCOME TAX ACT 1961 AND RULES MADE THEREUNDER </div>
            <div className='gh2'>Income Tax Exemption (80-G) Number AIXSXX000000XX</div>

            <div className='details'>
                <div className='bank'>
                    <div className='line'>Donation Through Bank (NEFT/ RTGS)</div>
                    <p>Bank Name - Yes Bank
                        <br />
                        Account Name - INTERNATIONAL SOCIETY FOR KRISHNA CONSCIOUSNESS
                        <br />
                        Account Number - 00000001111110000
                        <br />
                        IFSC Code - XXX00000111</p>
                </div>
                <div className='pytm'>
                    <div className='line'>Pay by PAYTM</div>
                    <p>Gentle Request! While doing Paytm or Bank (NEFT/ RTGS)
                        <br />
                        please send us screen shot on our
                        <br />
                        Whatsapp No- +91-00000000000.
                        <br />
                        You may also call on this no. for other queries</p>
                </div>
                <div className='qr'>
                    <div className='line'>Pay by UPI</div>
                    <img className='qrimg' src='https://iskcondwarka.org/sudama-seva-donation/img/01_gallery.jpg' alt='qr code ' />
                    <br />
                    <p>IFSC Code - YESB0000932</p>
                </div>
            </div>


            <div className='container'>
                <div> <img src='https://iskcondwarka.org/sudama-seva-donation/img/sudama.png' alt='sudama' /> </div>
                <div className='benefits'>
                    <span> BENEFITS OF SUDAMA SEWA </span>
                    <p> Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae cum amet tenetur, ad ipsum nesciunt dicta! </p>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestias culpa voluptate architecto ipsa fuga, eos nobis?</p>
                    <p> Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro dolore illum suscipit autem aspernatur molestias! Odio fuga iure hic. </p>
                </div>
            </div>

            <div className='poster'>
                <div> <img src='https://iskcondwarka.org/sudama-seva-donation/img/hindi-text.png' alt='hindi' /> </div>
                <div className='entrance'> <img src='https://iskcondwarka.org/sudama-seva-donation/img/entrance.jpg' alt='entrance' /> </div>
            </div>

            <div>
                <div className='head'><span> ISKCON ETAWAH - CONSTRUCTION</span></div>
                <div className='img-const'>
                    <div className='const'><img src='https://iskcondwarka.org/sudama-seva-donation/img/01_gallery.jpg' alt='construction images' /></div>
                    <div className='const'><img src='https://iskcondwarka.org/sudama-seva-donation/img/01_gallery.jpg' alt='construction images' /></div>
                    <div className='const'><img src='https://iskcondwarka.org/sudama-seva-donation/img/01_gallery.jpg' alt='construction images' /></div>
                    <div className='const'><img src='https://iskcondwarka.org/sudama-seva-donation/img/01_gallery.jpg' alt='construction images' /></div>
                    <div className='const'><img src='https://iskcondwarka.org/sudama-seva-donation/img/01_gallery.jpg' alt='construction images' /></div>
                    <div className='const'><img src='https://iskcondwarka.org/sudama-seva-donation/img/01_gallery.jpg' alt='construction images' /></div>
                </div>
            </div>

        </div>
    )
}

export default Donation;